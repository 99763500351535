import React from 'react';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as DisabledTrackingPlan } from './disabled_tracking_plan.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Check } from './check.svg';
import { ReactComponent as FilledCheckWS } from './check_workspace_menu_filled.svg';
import { ReactComponent as Workspace } from './workspace.svg';
import { ReactComponent as WorkspaceFilled } from './workspace_filled.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as DestTransformationActive } from './dest-transformation-active.svg';
import { ReactComponent as DestTransformationInactive } from './dest-transformation-inactive.svg';
import { ReactComponent as Pause } from './pause.svg';
import { ReactComponent as Warning } from './warning.svg';
import { ReactComponent as GithubLogo } from './github-logo.svg';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { ReactComponent as RightArrow } from './rightArrow.svg';
import { ReactComponent as AddMfa } from './add_mfa.svg';
import { ReactComponent as EmptyBox } from './empty_box.svg';
import { ReactComponent as EmptyList } from './emptyTrackingPlansList.svg';
import { ReactComponent as SyncFailed } from './sync_failed.svg';
import { ReactComponent as SyncAborted } from './sync_aborted.svg';
import { ReactComponent as SyncExecuting } from './sync_executing.svg';
import { ReactComponent as SyncWaiting } from './sync_waiting.svg';
import { ReactComponent as Search } from './magnifier.svg';
import { ReactComponent as ShortLeft } from './short_left.svg';
import { ReactComponent as ThinLongRight } from './thin_long_right.svg';
import { ReactComponent as Party } from './party.svg';
import { ReactComponent as Blendo } from './blendo.svg';
import { ReactComponent as CircleCheck } from './circle_check.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as MFASuccess } from './mfa_success.svg';
import { ReactComponent as MatchNotFound } from './match_not_found.svg';
import { ReactComponent as ActionSuccess } from './action_success.svg';
import { ReactComponent as DirectoryRequest } from './directory_request.svg';
import { ReactComponent as LinkResource } from './link_resource.svg';
import { ReactComponent as Dashboard } from './dashboard.svg';
import { ReactComponent as Mail } from './mail.svg';
import { ReactComponent as Book } from './book.svg';
import { ReactComponent as GetStarted } from './sidebarRevamp/get-started.svg';
import { ReactComponent as GetStartedActive } from './sidebarRevamp/get-started-active.svg';
import { ReactComponent as SidebarRevampDirectory } from './sidebarRevamp/directory.svg';
import { ReactComponent as SidebarRevampDirectoryActive } from './sidebarRevamp/directory-active.svg';
import { ReactComponent as SidebarRevampCollect } from './sidebarRevamp/collect.svg';
import { ReactComponent as SidebarRevampCollectActive } from './sidebarRevamp/collect-active.svg';
import { ReactComponent as SidebarRevampUnify } from './sidebarRevamp/unify.svg';
import { ReactComponent as SidebarRevampUnifyActive } from './sidebarRevamp/unify-active.svg';
import { ReactComponent as SidebarRevampGovern } from './sidebarRevamp/govern.svg';
import { ReactComponent as SidebarRevampGovernActive } from './sidebarRevamp/govern-active.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as Management } from './management.svg';
import { ReactComponent as SidebarRevampActivate } from './sidebarRevamp/activate.svg';
import { ReactComponent as SidebarRevampActivateActive } from './sidebarRevamp/activate-active.svg';
import { ReactComponent as SidebarRevampMonitor } from './sidebarRevamp/monitor.svg';
import { ReactComponent as SidebarRevampMonitorActive } from './sidebarRevamp/monitor-active.svg';
import { ReactComponent as SidebarRevampSetting } from './sidebarRevamp/settings.svg';
import { ReactComponent as SidebarRevampSettingActive } from './sidebarRevamp/settings-active.svg';
import { ReactComponent as NoResultsFound } from './no_results_found.svg';
import { ReactComponent as DataResidencyPlaceholder } from './data_residency_placeholder.svg';
import { ReactComponent as USFlag } from './flags/us.svg';
import { ReactComponent as EUFlag } from './flags/europe.svg';
import { ReactComponent as ErrorPageBgImage } from './error-page-bg-image.svg';
import { ReactComponent as MagnifyGlassCheck } from './magnify_glass_check.svg';
import { ReactComponent as Signup } from './signup/signup.svg';
import { ReactComponent as AccountSetup } from './signup/account_setup.svg';
import { ReactComponent as NewLogo } from './new-logo.svg';
import { ReactComponent as NewLogoWhite } from './new_logo_white.svg';
import { ReactComponent as NewMobileLogo } from './new-mobile-logo.svg';
import { ReactComponent as SourceTrackingPlan } from './source_tracking_plan.svg';
import { ReactComponent as EmptyWorkspaces } from './empty-workspaces.svg';
import { ReactComponent as ConnectionComplete } from './connection-complete.svg';

interface SvgProps extends React.SVGAttributes<unknown> {
  name:
    | 'info'
    | 'github'
    | 'disabled-tracking-plan'
    | 'search'
    | 'thin-long-right'
    | 'short-left'
    | 'check'
    | 'pause'
    | 'warning'
    | 'edit'
    | 'delete'
    | 'close'
    | 'filled-check_ws_menu'
    | 'party'
    | 'google'
    | 'blendo'
    | 'signup'
    | 'add_mfa'
    | 'workspace-filled'
    | 'workspace'
    | 'link-resource'
    | 'dashboard'
    | 'book'
    | 'mail'
    | 'data_residency_placeholder'
    | 'directory_request'
    | 'circle_check'
    | 'match_not_found'
    | 'dest-transformation-inactive'
    | 'dest-transformation-active'
    | 'mfa_success'
    | 'error-page-bg-image'
    | 'account-setup'
    | 'us-flag'
    | 'eu-flag'
    | 'action_success'
    | 'sync-aborted'
    | 'sync-waiting'
    | 'sync-failed'
    | 'sync-executing'
    | 'empty_box'
    | 'no_results_found'
    | 'empty_list'
    | 'settings-active'
    | 'settings'
    | 'monitor-active'
    | 'monitor'
    | 'get-started-active'
    | 'get-started'
    | 'directory-active'
    | 'directory'
    | 'unify-active'
    | 'unify'
    | 'govern-active'
    | 'govern'
    | 'activate-active'
    | 'activate'
    | 'support'
    | 'management'
    | 'collect-active'
    | 'collect'
    | 'right-arrow'
    | 'new-logo'
    | 'new-logo-white'
    | 'new-mobile-logo'
    | 'magnifier-check'
    | 'source-tracking-plan'
    | 'empty-workspaces'
    | 'connection-complete';
  size?: number;
  fill?: string;
}

const Svg = ({ name, size, ...svgProps }: SvgProps) => {
  let Icon;
  switch (name) {
    case 'get-started':
      Icon = GetStarted;
      break;
    case 'get-started-active':
      Icon = GetStartedActive;
      break;
    case 'directory':
      Icon = SidebarRevampDirectory;
      break;
    case 'directory-active':
      Icon = SidebarRevampDirectoryActive;
      break;
    case 'collect':
      Icon = SidebarRevampCollect;
      break;
    case 'collect-active':
      Icon = SidebarRevampCollectActive;
      break;
    case 'unify':
      Icon = SidebarRevampUnify;
      break;
    case 'unify-active':
      Icon = SidebarRevampUnifyActive;
      break;
    case 'govern':
      Icon = SidebarRevampGovern;
      break;
    case 'govern-active':
      Icon = SidebarRevampGovernActive;
      break;
    case 'activate':
      Icon = SidebarRevampActivate;
      break;
    case 'support':
      Icon = Support;
      break;
    case 'management':
      Icon = Management;
      break;
    case 'activate-active':
      Icon = SidebarRevampActivateActive;
      break;
    case 'monitor':
      Icon = SidebarRevampMonitor;
      break;
    case 'monitor-active':
      Icon = SidebarRevampMonitorActive;
      break;
    case 'settings':
      Icon = SidebarRevampSetting;
      break;
    case 'settings-active':
      Icon = SidebarRevampSettingActive;
      break;
    case 'right-arrow':
      Icon = RightArrow;
      break;
    case 'connection-complete':
      Icon = ConnectionComplete;
      break;
    case 'disabled-tracking-plan':
      Icon = DisabledTrackingPlan;
      break;
    case 'delete':
      Icon = Delete;
      break;
    case 'close':
      Icon = Close;
      break;
    case 'check':
      Icon = Check;
      break;
    case 'filled-check_ws_menu':
      Icon = FilledCheckWS;
      break;
    case 'workspace':
      Icon = Workspace;
      break;
    case 'workspace-filled':
      Icon = WorkspaceFilled;
      break;
    case 'edit':
      Icon = Edit;
      break;
    case 'new-logo':
      Icon = NewLogo;
      break;
    case 'new-logo-white':
      Icon = NewLogoWhite;
      break;
    case 'new-mobile-logo':
      Icon = NewMobileLogo;
      break;
    case 'dest-transformation-active':
      Icon = DestTransformationActive;
      break;
    case 'dest-transformation-inactive':
      Icon = DestTransformationInactive;
      break;
    case 'info':
      Icon = Info;
      break;
    case 'warning':
      Icon = Warning;
      break;
    case 'pause':
      Icon = Pause;
      break;
    case 'github':
      Icon = GithubLogo;
      break;
    case 'google':
      Icon = GoogleLogo;
      break;
    case 'sync-failed':
      Icon = SyncFailed;
      break;
    case 'sync-aborted':
      Icon = SyncAborted;
      break;
    case 'sync-executing':
      Icon = SyncExecuting;
      break;
    case 'sync-waiting':
      Icon = SyncWaiting;
      break;
    case 'search':
      Icon = Search;
      break;
    case 'short-left':
      Icon = ShortLeft;
      break;
    case 'thin-long-right':
      Icon = ThinLongRight;
      break;
    case 'signup':
      Icon = Signup;
      break;
    case 'account-setup':
      Icon = AccountSetup;
      break;
    case 'blendo':
      Icon = Blendo;
      break;
    case 'party':
      Icon = Party;
      break;
    case 'circle_check':
      Icon = CircleCheck;
      break;
    case 'link-resource':
      Icon = LinkResource;
      break;
    case 'dashboard':
      Icon = Dashboard;
      break;
    case 'mail':
      Icon = Mail;
      break;
    case 'book':
      Icon = Book;
      break;
    case 'add_mfa':
      Icon = AddMfa;
      break;
    case 'mfa_success':
      Icon = MFASuccess;
      break;
    case 'match_not_found':
      Icon = MatchNotFound;
      break;
    case 'empty_box':
      Icon = EmptyBox;
      break;
    case 'action_success':
      Icon = ActionSuccess;
      break;
    case 'empty_list':
      Icon = EmptyList;
      break;
    case 'directory_request':
      Icon = DirectoryRequest;
      break;
    case 'no_results_found':
      Icon = NoResultsFound;
      break;
    case 'data_residency_placeholder':
      Icon = DataResidencyPlaceholder;
      break;
    case 'us-flag':
      Icon = USFlag;
      break;
    case 'eu-flag':
      Icon = EUFlag;
      break;
    case 'error-page-bg-image':
      Icon = ErrorPageBgImage;
      break;
    case 'magnifier-check':
      Icon = MagnifyGlassCheck;
      break;
    case 'source-tracking-plan':
      Icon = SourceTrackingPlan;
      break;
    case 'empty-workspaces':
      Icon = EmptyWorkspaces;
      break;
    default:
      handleDefault(name);
      break;
  }

  const sizeProps =
    (size && {
      width: size,
      height: size,
    }) ||
    {};
  return Icon ? <Icon {...sizeProps} {...svgProps} /> : <div />;
};

const handleDefault = (name: never) => {
  throw Error(`invalid svg ${name}`);
};

/* eslint-disable import/no-default-export */
export default Svg;
