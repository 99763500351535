import React, { lazy, Suspense } from 'react';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { toJS } from 'mobx';
import styled from 'styled-components';
import TimePicker from '@components/common/pickers/timePicker';
import { ErrorLabel } from '@components/common/errorLabel';
import { FixMe } from '@utils/types';
import TextInputField from '@components/common/textInput/textInput';
import { H4 } from '@ui-library/typography';
import { SwitchInput } from './switchInput';
import { ISingleSelectOption, SingleSelect } from './singleSelect';
import TimeRangePicker from '../pickers/timeRangePicker';
import { DynamicForm } from './dynamicForm';
import DynamicCustomForm from './dynamicCustomForm';
import DynamicSelectForm from './dynamicSelectForm';
import DynamicSelect, { MetaData } from '@components/common/dynamicSelect';
import DatePicker from '../pickers/datePicker';
import { AdWordsFieldsSelect } from './GoogleAdsFields';
import { getFieldConfigs } from './utils';
import { CopyToClipboard } from '@components/common/icons/copyToClipboard';
import { inject, observer } from 'mobx-react';
import { IDestinationsListStore } from '@stores/destinationsList';
import { RouteComponentProps, withRouter } from 'react-router';
import { Skeleton } from 'antd';
import { ClientFeature, IFeaturesStore } from '@stores/features';

const DMTCallout = lazy(
  () =>
    import(
      /* webpackChunkName: "form group" */ '@components/destinations/destination-view/configurationV2/formComponents/callout/dmtCallout'
    ),
);

export interface CommonFieldProps {
  defaultValue: string;
  value: string;
  default?: string;
  required?: boolean;
  label: string;
}

interface IFormGroupProps extends RouteComponentProps<{ id: string }> {
  title: string;
  instruction: IInstruction | null | undefined;
  fields: FixMe;
  onStateChange: (e: FixMe, error: boolean) => void;
  initialSettings?: FixMe;
  secretConfig?: FixMe;
  disabled: boolean;
  metadata?: MetaData;
  sectionNote?: string | undefined;
  destinationsListStore?: IDestinationsListStore;
  featuresStore?: IFeaturesStore;
  sourceDependentType?: string;
  isEditMode?: boolean;
}

export interface IInstruction {
  message: string;
  link: string;
  linkText: string;
}

interface IFormGroupState {
  formData: Record<string, string | undefined>;
  error: boolean;
  errorMessage: string;
}

interface ISingleSelectOptionWithFeatureFlag extends ISingleSelectOption {
  featureFlag?: ClientFeature;
}

const FooterNote = styled.div.attrs({
  className: 'm-t-sm alert alert-primary',
})`
  overflow-wrap: break-word;
`;

@inject('destinationsListStore', 'featuresStore')
@observer
class FormGroup extends React.PureComponent<IFormGroupProps, IFormGroupState> {
  constructor(props: IFormGroupProps) {
    super(props);
    this.state = {
      formData: {},
      error: false,
      errorMessage: 'Wrong format',
    };
  }

  public onChange = (label: string, value?: string, error?: boolean) => {
    const { onStateChange } = this.props;
    this.setState(
      (prevProps) => {
        const newFormData = set({ ...prevProps.formData }, label, value);
        return {
          formData: newFormData,
        };
      },
      () => onStateChange(this.state.formData, Boolean(error)),
    );
  };

  public renderField = (field: FixMe, index: number, isEditMode?: boolean) => {
    const {
      initialSettings,
      secretConfig,
      disabled,
      metadata,
      destinationsListStore,
      featuresStore,
      match,
      sourceDependentType,
    } = this.props;
    const { formData, error, errorMessage } = this.state;
    if (initialSettings && initialSettings[field.value] !== undefined) {
      field.default = toJS(initialSettings[field.value]);
    }

    const availableFeatureFlags = featuresStore?.getCombinedFeatureFlagsList();
    const fieldConfig = getFieldConfigs(field, formData, availableFeatureFlags);
    if (fieldConfig === null) {
      return null;
    }

    const { forceDisabled, preRequisiteValue, preRequisiteObjectValue } = fieldConfig;
    const { id } = match.params;
    const destination = destinationsListStore!.destinationById(id)!;

    const MOBILE_SOURCE_TYPES = ['android', 'ios', 'reactnative', 'flutter'];
    const isMobileSourceDependent =
      sourceDependentType && MOBILE_SOURCE_TYPES.includes(sourceDependentType.toLowerCase());

    const filterOptionsByFeatureFlags = (options: ISingleSelectOptionWithFeatureFlag[]) =>
      options
        .filter((option) => !option.featureFlag || featuresStore?.has(option.featureFlag))
        .map(({ name, value }) => ({ name, value }));

    switch (field.type) {
      case 'textInput':
      case 'textareaInput':
        return (
          <div
            className="p-b-sm form_group__text_area_container"
            data-testid="fgTextInput"
            key={index}
          >
            <div className="full-width">
              <TextInputField
                field={field}
                onChange={this.onChange}
                type={field.type === 'textInput' ? 'input' : 'textarea'}
                disabled={disabled || forceDisabled || (isEditMode && field.immutable)}
                secret={initialSettings && secretConfig && secretConfig[field.value]}
              />
              {error ? <ErrorLabel error={error} errorMessage={errorMessage} /> : null}
              {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
              {field.footerURL && (
                <FooterNote>
                  <a href={field.footerURL.link} target="_blank" rel="noopener noreferrer">
                    {field.footerURL.text}
                  </a>
                </FooterNote>
              )}
            </div>
          </div>
        );
      case 'textareaInputCopy':
        return (
          <div
            className="p-b-sm form_group__text_area_container"
            data-testid="fgTextInput"
            key={index}
          >
            <div className="full-width">
              <TextInputField field={field} onChange={this.onChange} type="textarea" disabled />
            </div>
            <CopyToClipboard className="m-t-sm" content={field.default} />
          </div>
        );
      case 'singleSelect':
        return (
          <div
            className="p-b-sm form_group__select_container"
            data-testid="fgsingleSelect"
            key={index}
          >
            <SingleSelect
              preRequisiteValue={preRequisiteValue}
              field={{
                ...field,
                options: filterOptionsByFeatureFlags(field.options),
              }}
              onChange={this.onChange}
              disabled={disabled || (isEditMode && field.immutable)}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'timePicker':
        return (
          <div className="p-b-sm" key={index}>
            <TimePicker
              disabled={disabled}
              field={field}
              options={field.options}
              onChange={this.onChange}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'timeRangePicker':
        return (
          <div className="p-b-sm" key={index}>
            <TimeRangePicker
              disabled={disabled}
              field={field}
              options={field.options}
              onChange={this.onChange}
            />
            {field.footerNote && <div className="p-t-sm p-b-sm">{field.footerNote}</div>}
          </div>
        );
      case 'checkbox':
        return (
          <div className="p-b-sm" data-testid="checkbox" key={index}>
            {!(disabled || forceDisabled) && field.value.includes('useNativeSDK') && (
              <Suspense fallback={<Skeleton loading />}>
                <DMTCallout destination={destination!} />
              </Suspense>
            )}
            <SwitchInput
              field={field}
              onChange={this.onChange}
              hidden={false}
              disabled={disabled || forceDisabled}
            />
            {field.footerNote && (
              <div className="p-t-sm p-b-sm m-t-sm alert alert-primary word-wrap">
                {field.footerNote}
              </div>
            )}
            {field.footerURL && (
              <FooterNote>
                <a href={field.footerURL.link} target="_blank" rel="noopener noreferrer">
                  {field.footerURL.text}
                </a>
              </FooterNote>
            )}
          </div>
        );
      case 'dynamicForm':
        return (
          <div className="p-b-sm" key={index}>
            <DynamicForm
              field={field}
              onChange={this.onChange}
              hidden={field.hidden}
              secret={initialSettings && secretConfig && secretConfig[field.value]}
              disabled={disabled || forceDisabled}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'dynamicCustomForm':
        return (
          <div className="p-b-sm" data-testid="dynamicCustomForm" key={index}>
            <DynamicCustomForm
              field={field}
              onChange={this.onChange}
              disabled={disabled || forceDisabled}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'dynamicSelectForm':
        return (
          <div className="p-b-sm" key={index}>
            <DynamicSelectForm
              field={field}
              onChange={this.onChange}
              options={field.options}
              disabled={disabled || forceDisabled}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      // DynamicSelect
      case 'dynamicSelect':
        return (
          <div className="p-b-sm" key={index}>
            <DynamicSelect
              metadata={metadata}
              field={field}
              preRequisiteValue={preRequisiteValue}
              preRequisiteObjectValue={preRequisiteObjectValue}
              onChange={this.onChange}
              options={field.options}
              disabled={disabled || forceDisabled}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      // DynamicSelect
      case 'customGoogleAds':
        return (
          <div className="p-b-sm" data-testid="adWords" key={index}>
            <AdWordsFieldsSelect
              required={field.required}
              label={field.label}
              preRequisiteValue={preRequisiteValue}
              onChange={this.onChange}
              disabled={disabled || forceDisabled}
              valueName={field.value}
              defaultValue={field.default}
            />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'defaultCheckbox':
        return (
          <div className="p-b-sm" data-testid="defaultCheckbox" key={index}>
            This is a <strong>device-mode only</strong> destination.
            {isMobileSourceDependent && ' Please add Factory in your implementation.'}
            <SwitchInput field={field} hidden={false} onChange={this.onChange} disabled />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );
      case 'datePicker':
        return (
          <div
            className="p-b-sm form_group__date_picker_container"
            data-testid="customDatePicker"
            key={index}
          >
            <DatePicker field={field} onChange={this.onChange} disabled={disabled} />
            {field.footerNote && <FooterNote>{field.footerNote}</FooterNote>}
          </div>
        );

      default:
        break;
    }
    return null;
  };

  public render() {
    const { title, instruction, fields, sectionNote, isEditMode } = this.props;
    return (
      <div className="p-b-md p-t-sm form_group_container" data-testid="formGroup">
        {title ? <H4 className="p-b-sm">{title}</H4> : null}
        {instruction && (
          <div className="alert alert-secondary">
            {instruction.message}
            <a href={instruction.link} className="alert-link" target="_blank" rel="noreferrer">
              {instruction.linkText}
            </a>
          </div>
        )}
        {sectionNote ? <div className="p-t-sm p-b-sm">{sectionNote}</div> : null}
        {fields.map((field: FixMe, index: number) =>
          this.renderField(cloneDeep(field), index, isEditMode),
        )}
      </div>
    );
  }
}

const FormGroupWithRouter = withRouter(FormGroup);

/* eslint-disable import/no-default-export */
export default FormGroupWithRouter;
