/* eslint-disable @typescript-eslint/no-unused-vars */
import { useLocation } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { Skeleton } from 'antd';
import { ISource } from '@stores/source';
import { ISourceDataStore } from '@stores/sourceDataStore';
import { TAB_NAMES } from '@components/sources/source-view/utils';
import { DIRECTORY_TABS } from '@components/common/constants';
import { AccountValidator, CredentialsAuth, DirectoryTab, SourceCategory } from './sourceCategory';
import { SYNC_BEHAVIOUR_MODES } from '../visualizer/mappingScreens/sourceUtil';
import { getOriginHelpers } from '../source/warehouseSource/sourceOriginHelpers';
import NoDestinationTabView from '../source-view/source-destinations/noDestinationTabView';
import { IDestination } from '@stores/destination';
import {
  isFeatureEnabledForDestination,
  DestinationExperimentKeys,
} from '@lib/destinationExperiment';

const WarehouseAccounts = lazy(
  () =>
    import(
      /* webpackChunkName: "warehouseFactory.dataWarehouseAuth" */ '@components/common/warehouseAccounts'
    ),
);

const SchemaValidation = lazy(
  () =>
    import(
      /* webpackChunkName: "warehouseFactory.schemaValidation" */ '@components/sources/source/warehouseSource/warehouseSettings/validateSource'
    ),
);

const RetlSyncsV2 = lazy(() =>
  import(
    /* webpackChunkName: "sources.retlSyncsV2" */ '@components/sources/sourceDetails/retlSyncsV2'
  ).then((module) => ({
    default: module.RetlSyncsV2,
  })),
);

class WarehouseSource extends SourceCategory {
  override isNewConnectionAllowed(source: { destinations: unknown[] }) {
    return true;
  }

  /**
   * Allow the destination to be used by multiple sources when no other sources
   * are connected to the destination or when the feature flag
   * many-to-one-by-destination-type is enabled and that destination has only
   * warehouse sources connected
   */
  override isDestinationAllowedForConnection(destination: IDestination) {
    if (destination.sources.length === 0) {
      return true;
    }

    return (
      isFeatureEnabledForDestination(
        destination.destinationDefinition.name,
        DestinationExperimentKeys.enableManyToOneByDestinationType,
      ) && destination.sources.every((source) => source.sourceDef.category === 'warehouse')
    );
  }

  override async validateAccount({
    accountId,
    valid,
    accountInfo,
    source,
    setCredentials,
  }: AccountValidator) {
    if (valid) {
      const accountData = {
        accountId,
        accountInfo: valid ? accountInfo : undefined,
        role: source?.sourceDef.name,
      };
      const account = await source?.createAccount(accountData);
      if (account) {
        setCredentials({
          accountId: account.id,
          accountInfo: account.options,
          valid: true,
          updated: true,
        });
      }
      return account?.id;
    }
    return undefined;
  }

  override getAccountsEndpoint(sourceDef?: string) {
    const base = '/warehouseSources/accounts';
    if (sourceDef) {
      return `${base}/${sourceDef}`;
    }
    return base;
  }

  override getSourceType(_: { name: string }) {
    return 'warehouse';
  }

  override hideFromAllowedDestConnections() {
    return true;
  }

  override isWarehouse() {
    return true;
  }

  override handleSetup(_: boolean, canCreateWarehouse: boolean, create: () => void) {
    if (canCreateWarehouse) {
      create();
      return true;
    }
    return false;
  }

  override isPartOf(tab: DirectoryTab) {
    return tab === DIRECTORY_TABS.WAREHOUSE_ACTIONS;
  }

  override getAuthComponent({
    handleChange,
    source,
    isValidationDisabled,
    checkValid,
    isValidationLoading,
    credentials,
    ref,
  }: CredentialsAuth) {
    const location = useLocation();
    return (
      <>
        <Suspense fallback={<Skeleton active />}>
          <WarehouseAccounts
            isEditFlow={location.pathname.includes('/sources')}
            sourceDefinition={source?.sourceDef}
            onChange={handleChange}
            isValidationDisabled={isValidationDisabled}
            selectedAccount={
              credentials?.accountId !== undefined
                ? credentials?.accountId
                : source?.config?.rudderAccountId || source?.config?.accountId
            }
            checkValid={checkValid}
            isValidationLoading={isValidationLoading}
          />
        </Suspense>
        <div ref={ref}>
          {!isValidationDisabled && (
            <Suspense fallback={<Skeleton active />}>
              <SchemaValidation
                status={isValidationLoading || 'hide'}
                checkValid={checkValid}
                sName={source?.sourceDef.name || ''}
                accountId={credentials?.accountId || ''}
                accountInfo={credentials?.accountInfo}
                sourceDisplayName={source?.sourceDef.displayName || ''}
              />
            </Suspense>
          )}
        </div>
      </>
    );
  }

  override enableCredentialValidations() {
    return true;
  }

  override isReportsViewEnabled() {
    return true;
  }

  override isSyncDelayEnabled() {
    return true;
  }

  override getTabs(_: ISource) {
    return [TAB_NAMES.DESTINATIONS, TAB_NAMES.RETL_CONFIGURATION, TAB_NAMES.SETTINGS];
  }

  override showSyncScheduleSettings() {
    return true;
  }

  override hasIncompatibleSyncMode(
    srcSyncBehaviours?: string[],
    destSyncBehaviours?: string[],
  ): boolean {
    return !(destSyncBehaviours || [SYNC_BEHAVIOUR_MODES.UPSERT]).some(
      (destSyncMode: string) =>
        destSyncMode === SYNC_BEHAVIOUR_MODES.UPSERT ||
        (srcSyncBehaviours || []).includes(destSyncMode),
    );
  }

  override getCredentialSettings(sourceDataStore: ISourceDataStore, isAdmin: boolean) {
    const { origin } = sourceDataStore;
    return getOriginHelpers(origin).getCredentialSettings(sourceDataStore, isAdmin);
  }

  override getSyncs(source: ISource, hasSourcePermission: boolean) {
    if (source.destinations.length === 0) {
      return <NoDestinationTabView tabName="SYNCS" sourceID={source.id} />;
    }
    const sourceId = source.id;
    const destinationId = source.destinations[0].id;

    if (!sourceId || !destinationId) {
      return null;
    }

    return (
      <Suspense fallback={<Skeleton loading />}>
        <RetlSyncsV2
          sourceId={sourceId}
          destinationId={destinationId}
          isAdmin={hasSourcePermission}
        />
      </Suspense>
    );
  }

  override getBasicSyncScheduleOptions() {
    const options = [
      {
        name: 'Every 24 hours',
        value: 1440,
      },
      {
        name: 'Every 12 hours',
        value: 720,
      },
      {
        name: 'Every 6 hours',
        value: 360,
      },
      {
        name: 'Every 3 hours',
        value: 180,
      },
      {
        name: 'Every 1 hour',
        value: 60,
      },
      {
        name: 'Every 30 minutes',
        value: 30,
      },
      {
        name: 'Every 15 minutes',
        value: 15,
      },
      {
        name: 'Every 10 minutes',
        value: 10,
      },
      {
        name: 'Every 5 minutes',
        value: 5,
      },
    ];
    return options;
  }

  override getCronSyncSchedule() {
    const minFreq = 5;
    return {
      validate: (secondDiff: number, thirdDiff: number) =>
        secondDiff < minFreq || thirdDiff < minFreq,
      minFreq,
    };
  }
}

/* eslint-disable import/no-default-export */
export default WarehouseSource;
