import { OrganizationRole, WorkspaceRole } from '@stores/member';

export const RESOURCE_TYPES = {
  SOURCE: 'source',
  DESTINATION: 'destination',
  TRANSFORMATION: 'transformation',
} as const;

export const DIRECTORY_TABS = {
  ALL_SOURCES: 'All Sources',
  EVENT_STREAMS: 'Event Streams',
  CLOUD_EXTRACT: 'Cloud Extract',
  WAREHOUSE_ACTIONS: 'Reverse ETL',
  POPULAR_SOURCE: 'Popular',
  DESTINATIONS: 'Destinations',
  WAREHOUSE_DESTINATIONS: 'Warehouse Destinations',
  CLOUD_DESTINATIONS: 'Cloud Destinations',
  POPULAR_DESTINATION: 'Popular',
};

export const RUDDER_SCOPES = {
  delivery: 'delivery',
  delete: 'delete',
} as const;

export const SOURCE_CATEGORIES = {
  CLOUD: 'cloud',
  SINGER: 'singer-protocol',
  WAREHOUSE: 'warehouse',
  WEBHOOK: 'webhook',
} as const;

export const DESTINATION_CATEGORIES = {
  WAREHOUSE: 'warehouse',
} as const;

export const ACCESS_TOKEN_ROLES: {
  value: 'admin' | 'read-write' | 'member';
  label: string;
  level: number;
}[] = [
  {
    value: 'admin',
    label: 'Admin',
    level: 300,
  },
  {
    value: 'read-write',
    label: 'Read-Write',
    level: 200,
  },
  {
    value: 'member',
    label: 'Read-Only',
    level: 100,
  },
];

export const USER_ROLES_ACCESS_TOKEN: {
  value: 'admin' | 'read-write' | 'member';
  organisationRole: OrganizationRole;
  workspaceRoles?: WorkspaceRole[];
  label: string;
}[] = [
  {
    value: 'admin',
    organisationRole: OrganizationRole.admin,
    label: 'Admin',
  },
  {
    value: 'read-write',
    organisationRole: OrganizationRole.member,
    workspaceRoles: [
      WorkspaceRole.transformationsAdmin,
      WorkspaceRole.connectionsAdmin,
      WorkspaceRole.connectionsEdit,
      WorkspaceRole.modelsAdmin,
    ],
    label: 'Read-Write',
  },
  {
    value: 'member',
    organisationRole: OrganizationRole.member,
    workspaceRoles: [
      WorkspaceRole.workspaceRead,
      WorkspaceRole.transformationsAdmin,
      WorkspaceRole.connectionsAdmin,
      WorkspaceRole.connectionsEdit,
      WorkspaceRole.modelsAdmin,
    ],
    label: 'Read-Only',
  },
];

const EmptyRegex = '^$';
const NameRegex = '^[A-Za-z-_. 0-9]{2,50}$';
const DescriptionRegex = '^[A-Za-z-_.;,/() 0-9]{2,200}$';

export const REGEX_STRING = {
  SOURCE_NAME: NameRegex,
  ORIGIN_NAME: NameRegex,
  ORIGIN_DESCRIPTION: DescriptionRegex,
  RETL_SOURCE_TYPE_DESCRIPTION: DescriptionRegex,
  DESTINATION_NAME: NameRegex,
  TRANSFORMATION_NAME: NameRegex,
  TRANSFORMATION_DESCRIPTION: `${EmptyRegex}|${DescriptionRegex}`,
  LIBRARY_NAME: NameRegex,
  LIBRARY_DESCRIPTION: `${EmptyRegex}|${DescriptionRegex}`,
  EMAIL: '^[^\\s@]+@[^\\s@]+.[^\\s@]+$',
  TOKEN_NAME: '^[0-9a-zA-Z_ -]{1,64}$',
  ORGANIZATION_NAME: '^[A-Za-z-_. 0-9]{2,50}$',
  WORKSPACE_NAME: '^[A-Za-z-_.+ 0-9]{2,50}$',
  USER_NAME: "^[0-9A-Za-z ,.'-]{1,50}$",
  WHT_PROJECT_NAME: NameRegex,
  WHT_PROJECT_DESCRIPTION: `${EmptyRegex}|${DescriptionRegex}`,
  GIT_REPO_URL: '^git@(github.com|bitbucket.org|gitlab.com):.+/.+$',
  SSH_REPO_URL: /^ssh:\/\/git@([\w.-]+)?:?\d*\/.+$/,
  GIT_REPO_SSH_KEY: '^-----BEGIN OPENSSH PRIVATE KEY-----(.|\n)*-----END OPENSSH PRIVATE KEY-----$',
  WHT_WAREHOUSE_FIELDS: '^.+$',
  TRACKING_PLAN_NAME: NameRegex,
  TRACKING_PLAN_DESCRIPTION: `${EmptyRegex}|${DescriptionRegex}`,
  PROFILES_CONCURRENCY: '^([1-9]\\d?|100)$',
  CREDENTIAL_KEY_NAME: '^(?!\\d)\\w+$',
  SERVICE_ACCESS_TOKEN_NAME: NameRegex,
};

export const REGEX_ERROR_MESSAGE = {
  NAME_REGEX:
    'Name must be between 2 and 50 characters long, start with a letter and contain only letters, numbers, underscores, spaces, dashes, and dots.',
  DESCRIPTION_REGEX:
    'Description must be between 2 and 200 characters long, start with a letter and contain only letters, numbers, underscores, commas, spaces, dashes, semicolon, forward slash, parenthesis and dots.',
};

export const URLS = {
  TERMS_OF_SERVICE: 'https://rudderstack.com/master-service-agreement',
  DATA_RETENTION_EVENTS: 'https://www.rudderstack.com/docs/dashboard-guides/data-retention',
  DATA_RETENTION_REPORTING:
    'https://www.rudderstack.com/docs/dashboard-guides/data-retention/#sample-event-data',
  PRIVACY_POLICY: 'https://rudderstack.com/privacy-policy',
  PRICING: 'https://rudderstack.com/pricing',
  DOCS: 'https://docs.rudderstack.com',
  ADD_TRANSFORMATION: 'https://rudderstack.com/docs/transformations/#adding-a-transformation',
  ACCESS_TOKEN: 'https://rudderstack.com/docs/transformations/api-access-token/',
  API: 'https://docs.rudderstack.com/rudderstack-api',
  SAMPLE_TRANSFORMATIONS: 'https://github.com/rudderlabs/sample-user-transformers',
  SLACK_INVITE:
    'https://join.slack.com/t/rudderstack/shared_invite/zt-2iv7lq1us-mZ46TElfx7U_0iHy0~0V3A',
  VIDEO_LIBRARY: 'https://www.youtube.com/channel/UCgV-B77bV_-LOmKYHw8jvBw',
  TRACKING_PLAN_DOCS: 'https://www.rudderstack.com/docs/features/data-governance/tracking-plans/',
  TRACKING_PLAN_MIGRATION:
    'https://www.rudderstack.com/docs/data-governance/tracking-plans/#migrate-tracking-plans',
  RUDDERSTACK_OVERVIEW: 'https://www.youtube.com/watch?v=vOaw9pKn6_M',
  CUSTOMER_METRICS:
    'https://www.rudderstack.com/blog/a-guide-to-customer-metrics-what-to-track-and-why',
  WHT_PROJECT_SETUP: 'https://rudderlabs.github.io/pywht/',
  DESTINATION_DOCS: 'https://www.rudderstack.com/docs/dashboard-guides/destinations/',
  SOURCE_DOCS: 'https://www.rudderstack.com/docs/sources/',
  DESTINATION_OVERVIEW_DOCS: 'https://www.rudderstack.com/docs/destinations/overview/',
  CONNECTIONS_DOCS: 'https://www.rudderstack.com/docs/destinations/rudderstack-connection-modes/',
  LIVE_EVENTS_DOCS: 'https://www.rudderstack.com/docs/dashboard-guides/live-events/',
  DATA_RESIDENCY_DOCS: 'https://www.rudderstack.com/docs/data-residency/',
  UPDATES: 'https://www.rudderstack.com/docs/releases/',
  TRANSFORMATION_TEMPLATE: 'https://www.rudderstack.com/docs/features/transformations/templates/',
  TEAMS: 'https://www.rudderstack.com/docs/dashboard-guides/user-management/',
  DEVICE_MODE_TRANSFORMATIONS:
    'https://www.rudderstack.com/docs/features/transformations/device-mode-transformations/',
  QUICKSTART_GUIDE: 'https://www.rudderstack.com/docs/get-started/quickstart/',
  BLOG: 'https://www.rudderstack.com/blog/',
  DATA_GOVERNANCE_AUDIT: 'https://www.rudderstack.com/docs/api/event-audit-api/',
  GEOLOCATION_ENRICHMENT: 'https://www.rudderstack.com/docs/features/geolocation-service',
  CUSTOM_ALERTS: 'https://www.rudderstack.com/docs/data-governance/alerts/#custom-alerts',
  CREDENTIAL_STORE: 'https://rudderstack.com/docs/transformations/credentials/',
  SERVICE_ACCESS_TOKEN: '',
};

export const SESSION_STORAGE_KEYS = {
  source: 'OauthMetadata',
  destination: 'DestOauthMetadata',
};

export const VALIDATION_ERRORS = {
  INVALID_NAME: (entity: string) => `Please enter a valid ${entity} name`,
  NON_UNIQUE_NAME: (entity: string) => `Please enter a unique ${entity} name`,
  INVALID_DESCRIPTION: (entity: string) => `Please enter a valid ${entity} description`,
};

export enum Region {
  US = 'US',
  EU = 'EU',
}

export const REGIONS_INFO = {
  [Region.US]: {
    name: 'United States',
    shortName: 'US',
    svg: 'us-flag',
  },
  [Region.EU]: {
    name: 'Europe',
    shortName: 'EU',
    svg: 'eu-flag',
  },
} as const;

export const SUPPORT_EMAIL = 'support@rudderstack.com';

export const OBJECT_STORAGE_DEST_DEFINITION_NAMES = ['S3', 'GCS', 'AZURE_BLOB', 'MINIO'];

export const LATENCY_TOOLTIP_MESSAGES = {
  DEFINITION: 'Maximum latency experienced by 95% of data to reach the destination.',
  DEFINITION_WITH_SYNC_FREQUENCY:
    'Maximum latency experienced by 95% of data to reach the destination (includes sync frequency for warehouse destinations).',
  NO_DATA:
    'Latency data is not available for this destination due to zero event delivery in selected time range.',
  INSUFFICIENT_DATA:
    'Latency data is not available due to insufficient data within the selected time range. It will be available after October 14, 2024.',
  DISABLED_WH_LATENCY: 'Latency data is not available for warehouse destinations.',
  DISABLED_OBJECT_STORAGE_LATENCY: 'Latency data is not available for object storage destinations.',
};

export const WAREHOUSE_LATENCY_AVAILABILITY_DATE = '2024-10-14';
