import React from 'react';
import { FixMe } from '@utils/types';
import { Select, Option } from '@ui-library/select';
import { RedAsterisk } from '@components/common/redAsterisk';
import { LargeText } from '@ui-library/typographyV2';

export interface ISingleSelectOption {
  name: string;
  value: string;
}

interface ISingleSelectProps {
  field: {
    mode?: 'multiple';
    default?: FixMe;
    defaultOption?: { value: string | string[] };
    options: FixMe;
    label: string;
    required: boolean;
    placeholder: string;
    showSearch: boolean;
    labelInValue: boolean;
    loading: boolean;
    optionFilterProp: string;
    optionLabelProp: string;
    value?: FixMe;
  };
  preRequisiteValue?: string;
  type?: string;
  disabled?: boolean;
  onChange: (label: string, value: FixMe) => void;
}

export class SingleSelect extends React.Component<
  ISingleSelectProps,
  { value: string | string[] }
> {
  constructor(props: ISingleSelectProps) {
    super(props);
    this.state = {
      value: this.props.field.default || this.props.field?.defaultOption?.value,
    };
  }

  componentDidMount() {
    const { field } = this.props;
    this.onChange(field.default || field?.defaultOption?.value);
  }

  componentDidUpdate(prevProps: FixMe) {
    const { preRequisiteValue, field } = this.props;

    if (preRequisiteValue !== prevProps.preRequisiteValue) {
      this.onChange(field.mode === 'multiple' ? [] : '');
    }
  }

  renderOptions = () => {
    const { field, preRequisiteValue } = this.props;
    const { options } = field;
    let selectOptions = options || [];

    if (!Array.isArray(options)) {
      selectOptions = (preRequisiteValue && options.case[preRequisiteValue]) || [];
    }

    return selectOptions.map((option: string | ISingleSelectOption) => {
      const { name, value, ...rest } =
        typeof option !== 'object' ? { name: option, value: option } : option;

      return (
        <Option
          value={value}
          label={name}
          key={`single-select-opt-key-${value}`}
          {...rest}
          data-testid={value}
        >
          {name}
        </Option>
      );
    });
  };

  onChange = (v: FixMe) => {
    const { onChange, field } = this.props;
    const value = Array.isArray(v) && v.length === 0 ? undefined : v;
    this.setState({ value });
    onChange(field.value, value);
  };

  public render() {
    const { field, disabled } = this.props;
    const { value } = this.state;

    return (
      <div className="p-t-sm single-select_container" data-testid="singleSelectCont">
        <LargeText className="m-b-xs">
          {field.label}
          {field.required && <RedAsterisk />}
        </LargeText>
        <Select
          data-testid={field.label}
          placeholder={field.placeholder}
          showSearch={field.showSearch}
          disabled={disabled}
          value={value || field.default}
          mode={field.mode}
          defaultValue={field.default || field?.defaultOption?.value}
          onChange={this.onChange}
          labelInValue={field.labelInValue}
          loading={field.loading}
          optionFilterProp={field.optionFilterProp}
          optionLabelProp={field.optionLabelProp}
        >
          {this.renderOptions()}
        </Select>
      </div>
    );
  }
}
