import React from 'react';
import dayjs from 'dayjs';
import { action, observable, makeObservable, computed } from 'mobx';
import { apiAuthCaller } from '@services/apiCaller';
import { IRootStore } from '@stores/index';
import { ResourceCategory, ResourceType } from '@stores/alertDefinitionsList/types';
import { readableThreshold } from '../utils';

const ALERTS_API = '/alertNotifications/alerts/active';

interface RawActiveAlert {
  sourceId?: string;
  destinationId?: string;
  trackingPlanId?: string;
  jobId?: string;
  alertDefinitionName: string;
  activeAt: string;
  threshold?: number;
}

export interface ActiveAlert extends Omit<RawActiveAlert, 'activeAt' | 'threshold'> {
  resourceCategory: ResourceCategory;
  resourceType: ResourceType;
  alertDisplayName: string;
  alertMessage: string;
  activeAt: dayjs.Dayjs;
  threshold: string;
  sourceName?: string;
  destinationName?: string;
  trackingPlanName?: string;
}

type AlertConfigurationStatus =
  | {
      hasEventStreamDestinationAlerts: boolean;
      hasRETLAlerts: boolean;
      hasTrackingPlanAlerts: boolean;
    }
  | {
      hasAlerts: boolean;
    };

interface RawActiveAlertsData {
  activeAlerts: RawActiveAlert[];
  alertConfigurationStatus: AlertConfigurationStatus;
}

export type ResourceDetails = {
  resourceId: string;
  resourceName: string;
  resourceType: ResourceType;
  resourceCategory: ResourceCategory;
};

export interface IActiveAlertsStore {
  resourceDetails: ResourceDetails | undefined;
  activeAlerts: ActiveAlert[];
  alertConfigurationStatus: AlertConfigurationStatus | null;
  resourceAlertsConfigured: boolean;
  activeAlertsLoading: boolean;
  showActiveAlerts: boolean;
  load(): Promise<void>;
}

export class ActiveAlertsStore implements IActiveAlertsStore {
  protected rootStore: IRootStore;

  public resourceDetails: ResourceDetails | undefined;

  @observable public activeAlerts: ActiveAlert[] = [];

  @observable public alertConfigurationStatus: AlertConfigurationStatus | null = null;

  @observable public activeAlertsLoading = false;

  @observable private errorInLoadingActiveAlerts = false;

  constructor(rootStore: IRootStore, resourceDetails?: ResourceDetails) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.resourceDetails = resourceDetails;
  }

  @computed
  private get alertsFeatureAvailable() {
    return this.rootStore.featuresStore.has('NOTIFICATION_SUBSCRIPTIONS');
  }

  @computed
  public get showActiveAlerts() {
    return this.alertsFeatureAvailable && !this.errorInLoadingActiveAlerts;
  }

  @computed
  public get resourceAlertsConfigured() {
    if (
      this.alertConfigurationStatus &&
      typeof this.alertConfigurationStatus === 'object' &&
      'hasAlerts' in this.alertConfigurationStatus
    ) {
      return this.alertConfigurationStatus.hasAlerts;
    }
    return false;
  }

  @action.bound
  public async load() {
    this.errorInLoadingActiveAlerts = false;

    if (!this.alertsFeatureAvailable) {
      return;
    }
    const { messagesStore, alertDefinitionsListStore } = this.rootStore;
    this.activeAlertsLoading = true;
    const { resourceName, ...params } = this.resourceDetails || {};

    try {
      const resp = await Promise.all([
        apiAuthCaller().get<RawActiveAlertsData>(ALERTS_API, { params }),
        alertDefinitionsListStore.load(),
      ]);
      const { data } = resp[0];

      this.activeAlerts = this.constructActiveAlerts(data.activeAlerts);

      this.alertConfigurationStatus = data.alertConfigurationStatus;
    } catch (e) {
      messagesStore.showErrorMessage('Failed to load active alerts');
      this.errorInLoadingActiveAlerts = true;
    } finally {
      this.activeAlertsLoading = false;
    }
  }

  @action
  private constructActiveAlerts = (rawActiveAlerts: RawActiveAlert[]) => {
    const {
      alertDefinitionsListStore: { alertDefinitions },
      sourcesListStore: { sourceById },
      destinationsListStore: { destinationById },
      retlConnectionListStore: { getConnectionById },
      trackingPlanListStore,
    } = this.rootStore;

    const alerts = rawActiveAlerts.map((a) => {
      const alertDef = alertDefinitions.find((alertDef) => alertDef.name === a.alertDefinitionName);

      if (!alertDef) {
        return null;
      }

      const source = sourceById(a?.sourceId || '');
      const destination = destinationById(a.destinationId || '');
      const connection = getConnectionById(a.jobId || '');
      const connectionDestination = destinationById(connection?.destinationId || '');
      const trackingPlan = trackingPlanListStore.getById(a.trackingPlanId || '');

      return {
        ...a,
        activeAt: dayjs(a.activeAt),
        alertMessage: alertDef.messageTemplate,
        alertDisplayName: alertDef.displayName,
        resourceCategory: alertDef.resourceCategory,
        resourceType: alertDef.resourceType,
        threshold: readableThreshold(a.threshold, alertDef?.thresholdMetadata?.unit),
        sourceName: source?.name || '',
        destinationName: destination?.name || connectionDestination?.name || '',
        trackingPlanName: trackingPlan?.name || '',
      };
    });

    return alerts.filter((alert) => alert !== null) as ActiveAlert[];
  };
}

export const ActiveAlertsStoreCtx = React.createContext<IActiveAlertsStore | undefined>(undefined);

export const useActiveAlertsStore = () =>
  React.useContext(ActiveAlertsStoreCtx) as IActiveAlertsStore;
